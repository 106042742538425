.spacing {
  margin: 60px 50px 100px 50px;

  text-align: center;
}

ion-card-header.center-text {
  text-align: center;
}

ion-card-content ion-item {
  margin-bottom: 25px;
}

.wrapper {
  margin-top: 130px;
}

ion-card {
  margin-left: auto;
  margin-right: auto;
}

ion-card.link-sent {
  margin-top: 20px;
  background: url('../assets//images//dang.svg');
}

ion-card.link-sent ion-card-content {
  margin-right: 150px;
}

.wrapper ion-card {
  max-width: 350px;
}
