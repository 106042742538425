p.smallText {
  font-weight: thin;
  font-size: 12px;
}

ion-card,
ion-list {
  max-width: 768px;
  margin-left: auto !important;
  margin-right: auto !important;
}

ion-item {
  max-width: 768px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.center-wrapper {
  max-width: 768px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.bold {
  font-weight: bold !important;
}

.has-add-list-header {
  align-items: center;
}

.has-add-list-header ion-button {
  margin-right: 20px;
}

.has-add-list-header ion-button::part(native) {
  width: var(--cus-width, min-content);
  font-size: var(--cus-font-size, 500);
  padding: var(--cus-padding, 12px);
  border-radius: var(--cus-border-radius, 2px);
}
